import React from 'react'
import Layout from '../../components/layout'
import { JumbleWordCreator } from '../../components/ChallengeCreators'

const JumbleWordCreatorPage = ({ learningGoalId }) => (
  <Layout>
    <JumbleWordCreator learningGoalId={learningGoalId} />
  </Layout>
)

export default JumbleWordCreatorPage
